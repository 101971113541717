AndrewMartin.carousels = [];

AndrewMartin.carousel = () => {

    const activeButton = '<button class="slick-polygon-button">' +
                            '<svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
                                '<polygon id="path-1" points="15 0 14.3169392 1.99095879 1.99095879 14.3162292 0 15 1.99095879 15.6834158 14.3169392 28.0090412 15 30 15.6834158 28.0090412 28.0090412 15.6834158 30 15 28.0090412 14.3162292 15.6834158 1.99095879"></polygon>' +
                            '</svg>' +
                        '</button>';

    // Hero carousel
    document.querySelectorAll('.js-carousel--hero').forEach((el, i) => {
        el.classList.add('js-carousel--hero-' + i);

        let carousel = new Carousel({
            dots: true,
            customPaging: function() {
                return activeButton;
            },
            swipe: true,
            autoplay: true,
            autoplaySpeed: 7000,
            fade: true,
            speed: 300,
            pauseOnHover: false,
            pauseOnFocus: false
        }, 'hero-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);
    });


    // Grid carousel
    document.querySelectorAll('.js-carousel--image-grid').forEach((el, i) => {
        el.classList.add('js-carousel--image-grid-' + i);

        const header = el.previousElementSibling;

        let carousel = new Carousel({
            appendArrows: $(header),
            mobileFirst: true,
            slidesToShow: 1,
            responsive: [
                {
                    breakpoint: 680,
                    settings: "unslick"
                }
            ],
            dots: false,
            arrows: true,
            swipe: true,
            infinite: false,
            autoplay: false,
            fade: false,
            speed: 1000
        }, 'image-grid-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);
    });

     // Quote carousel
     document.querySelectorAll('.js-carousel--quote').forEach((el, i) => {
        el.classList.add('js-carousel--quote-' + i);

        let carousel = new Carousel({
            dots: true,
            customPaging: function() {
                return activeButton;
            },
            swipe: true,
            autoplay: false,
            fade: true,
            speed: 1000
        }, 'quote-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);
    });

    // Review carousel
    document.querySelectorAll('.js-carousel--reviews').forEach((el, i) => {
        el.classList.add('js-carousel--reviews-' + i);

        const header = el.closest('.overlay-reviews').previousElementSibling;

        let carousel = new Carousel({
            appendArrows: $(header),
            dots: false,
            swipe: true,
            arrows: true,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 999,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ],
            autoplay: false,
            speed: 1000
        }, 'reviews-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);
    });

    // Design review carousel
    document.querySelectorAll('.js-carousel--design-review').forEach((el, i) => {
        el.classList.add('js-carousel--design-review-' + i);

        let carousel = new Carousel({
            slidesToShow: 6,
            responsive: [
                {
                    breakpoint: 1920,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1680,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 999,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
            ],
            dots: false,
            arrows: true,
            swipe: true,
            infinite: false,
            autoplay: false,
            fade: false,
            speed: 1000
        }, 'design-review-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);
    });
    

    // Complete the look carousel (carbon copy of above)
    document.querySelectorAll('.js-carousel--product-recommended').forEach((el, i) => {

        if (el.classList.contains('slick-slider')) {
            return;
        }

        el.classList.add('js-carousel--product-recommended-' + i);

        const title = el.previousElementSibling;

        let carousel = new Carousel({
            appendArrows: $(title),
            slidesToShow: 6,
            responsive: [
                {
                    breakpoint: 1920,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1680,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 999,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
            ],
            dots: false,
            arrows: true,
            swipe: true,
            infinite: false,
            autoplay: false,
            fade: false,
            speed: 1000
        }, 'product-recommended-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);
    });


    // Get inspired carousel
    document.querySelectorAll('.js-carousel--inspired').forEach((el, i) => {
        el.classList.add('js-carousel--inspired-' + i);

        let carousel = new Carousel({
            slidesToShow: 1,
            dots: false,
            arrows: true,
            swipe: true,
            infinite: false,
            autoplay: false,
            fade: true,
            speed: 1000
        }, 'inspired-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);
    });

     // PDP callout carousel
     document.querySelectorAll('.js-carousel--product').forEach((el, i) => {
        el.classList.add('js-carousel--product-' + i);

        let carousel = new Carousel({
            slidesToShow: 1,
            dots: false,
            arrows: true,
            swipe: true,
            infinite: false,
            autoplay: false,
            fade: true,
            speed: 300
        }, 'product-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);
    });

    // PDP mobile gallery carousel
    document.querySelectorAll('.js-carousel--product-gallery').forEach((el, i) => {
        if (el.classList.contains('slick-slider')) {
            return;
        }

        el.classList.add('js-carousel--product-gallery-' + i);

        let carousel = new Carousel({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            filter: {
                selector: function() { return $('.slick-show', this).length === 1; },
                breakpoint: AndrewMartin.breakpoints.tablet.size
            },
            customPaging: function() {
                return activeButton;
            },
            swipe: true,
            infinite: true,
            autoplay: false,
            fade: true,
            cssEase: 'linear',
            speed: 100
        }, 'product-gallery-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);
    });

    // PDP gallery carousel
    document.querySelectorAll('.js-carousel--product-gallery-v2').forEach((el, i) => {
        if (el.classList.contains('slick-slider')) {
            return;
        }

        const prevButton = document.querySelector('[data-mobile-prev]');
        const nextButton = document.querySelector('[data-mobile-next]');
        const count = document.querySelector('[data-carousel-count]');

        if (window.matchMedia('(max-width: 999px)').matches && (nextButton || prevButton)) {
            prevButton.addEventListener('click', () => {
                $(el).slick('slickPrev');
            })
    
            nextButton.addEventListener('click', () => {
                $(el).slick('slickNext');
            })
    
            $(el).on('init reInit afterChange', (event, slick, currentSlide) => {
                //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                const i = (currentSlide ? currentSlide : 0) + 1;
                
                count.innerText = `${i}/${slick.slideCount}`
            });
        }

        el.classList.add('js-carousel--product-gallery-' + i);

        let carousel = new Carousel({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            swipe: true,
            infinite: false,
            autoplay: false,
            mobileFirst: true,
            dots: false,
            fade: true,
            cssEase: 'linear',
            asNavFor: '.js-carousel--product-thumbnails',
            speed: 100,
            responsive: [
                {
                    breakpoint: 999,
                    settings: {
                        swipe: false,
                    },
                },
            ],
        }, 'product-gallery-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);

        if (window.matchMedia('(max-width: 999px)').matches) {
            $(el).slick('slickFilter', function() {
                return $('.slick-show', this).length === 1;
            });
        }
    });

    // PDP thumbnails
    document.querySelectorAll('.js-carousel--product-thumbnails').forEach((el, i) => {
        if (el.classList.contains('slick-slider')) {
            return;
        }

        el.classList.add('js-carousel--product-thumbnails-' + i);

        let carousel = new Carousel({
            slidesToShow: 4.5,
            asNavFor: '.js-carousel--product-gallery-v2',
            focusOnSelect: true,
            infinite: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 999,
                    settings: "unslick",
                },
            ],
        }, 'product-thumbnails-' + i);
        carousel.init($(el));

        AndrewMartin.carousels.push(carousel);
    });
}

$(document).on('click', '.product__qty a, .product-piece__qty a, .product-piece__qty button', function(e){
    e.preventDefault();
    var input = $(this).siblings('.input');
    var step = typeof input.data('step') !== 'undefined' ? input.data('step') : 1;
    var decimal_places = typeof input.data('places') !== 'undefined' ? input.data('places') : 0;
    var minimum = typeof input.data('min') !== 'undefined' ? input.data('min') : 1;
    var maximum = typeof input.data('max') !== 'undefined' ? input.data('max') : false;

    if ($('svg', $(this)).hasClass('icon-plus')) {
        input.val((parseFloat(input.val()) + step).toFixed(decimal_places));
    } else if (parseInt(input.val()) >= 0) {
        input.val((parseFloat(input.val()) - step).toFixed(decimal_places));
    }

    if (maximum !== false && parseFloat(input.val()) > maximum) {
        input.val(maximum);
    }
    if (minimum !== false && parseFloat(input.val()) < minimum) {
        input.val(minimum);
    }

    input.trigger('change');
});

$(document).on('input change', '.product__qty input, .product-piece__qty input', function(e) {
    if ($('.js-customise-price-target--family').length > 0) {
        const target = $('.js-customise-price-target');

        var price = 0.00;
        var qty = $(this).val();

        price = target.data('price') * qty;

        if (price.toFixed(2) % 1 > 0) {
            price = price.toFixed(2);
        } else {
            price = price.toFixed(0);
        }

        target.find('.value').html(target.data('currency') + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }

    if ($('.product__multi__size__qty').length > 0) {
        var price = 0.00;
        var total_qty = 0;
        const quantities = $('.product__multi__size__qty:visible');
        let products = {};

        quantities.each(function (index, element) {
            let sku = $(element).data('sku');
            const qty = parseInt($(element).find('input[name=qty]').val());
            const product_price = parseFloat(parseFloat($(this).data('price')).toFixed(2));

            if (qty > 0) {
                price += (product_price * qty);
                total_qty += qty;
                products[sku] = qty;
            }
        });

        const keys = Object.keys(products);
        if (keys.length > 0) {
            $('.js-wishlist-action').removeClass('product__wishlist--disabled');
            $('.js-wishlist-action').attr('data-sku', keys.join(','));
        } else {
            $('.js-wishlist-action').addClass('product__wishlist--disabled');
        }

        if (price === 0) {
            var fromPrice = null;

            $('.product__multi__size').each(function() {
                var sizePrice = parseFloat($(this).find('.product__multi__size__price').data('price'));

                if (sizePrice < fromPrice || fromPrice === null) {
                    fromPrice = sizePrice;
                }
            })

            price = fromPrice;

            $('.product__multi__total_label').html('From:');
        } else {
            $('.product__multi__total_label').html('Total Price:');
        }

        if (price.toFixed(2) % 1 > 0) {
            price = price.toFixed(2);
        } else {
            price = price.toFixed(0);
        }

        $('.product__multi__total_value').html($('.product__multi__total_value').data('currency') + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

        var disable = total_qty <= 0;
        $('.product__button').toggleClass('button--disabled', disable);
    }

    if ($('.product__price__total_value').length > 0) {
        var product_price = parseFloat($('.product__qty').data('price'));
        var qty = parseFloat($(this).val());

        var price = product_price * qty;

        if (price.toFixed(2) % 1 > 0) {
            price = price.toFixed(2);
        } else {
            price = price.toFixed(0);
        }

        $('.product__price__total_value').html($('.product__price__total_value').data('currency') + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }

    var maximum = typeof $(this).data('max') !== 'undefined' ? $(this).data('max') : false;
    var minimum = typeof $(this).data('min') !== 'undefined' ? $(this).data('min') : 1;
    if (maximum !== false && parseFloat($(this).val()) > maximum) {
        $(this).val(maximum);
    }
    if (minimum !== false && parseFloat($(this).val()) < minimum && parseFloat($(this).val()) >= 0) {
        $(this).val(minimum);
    }
    if ($('.batches__totals').length > 0) {
        updateBatchPrices();
    }
})

var updateBatchPrices = function() {
    var price = 0.00;
    var total_qty = 0;
    var quantities = $('.batches__piece-qty:visible');
    var product_price = parseFloat(parseFloat($('.batches').attr('data-price')).toFixed(2));

    quantities.each(function(){
        var qty = parseFloat($(this).find('input[name=qty]').val());

        if (qty > 0) {
            price += product_price * qty;
            total_qty += qty;
        }
    });

    $('.batches__totals-main span').html($('.batches__totals-main').data('currency') + price.toFixed(2));
    $('.batches__totals-info span').html(total_qty.toFixed(1));
    $('.js-material-length').html(total_qty.toFixed(1));

    if (total_qty > 0) {
        $('.product__button').toggleClass('button--disabled', false);
        $('.js-wishlist-action').toggleClass('product__wishlist--disabled', false);
    } else {
        $('.product__button').toggleClass('button--disabled', true);
        $('.js-wishlist-action').toggleClass('product__wishlist--disabled', true);
    }
}
